// **************************
// Icons
// **************************
// NB : Ces règles sont en dehors du composant "IconComponent" car il faut que leur scope soit global

// Icons size & positionning
.mat-icon {
    font-size: 24px;
    &.margin {
        margin: 10px;
    }
    &.GREEN {
        color: $bt-green;
    }
    &.RED {
        color: $bt-red;
    }
    &.BLUE {
        color: $bt-blue;
    }
    &.ORANGE {
        color: $bt-orange;
    }
    &.YELLOW {
        color: $bt-yellow;
    }
    &.GREY {
        color: $bt-grey;
    }
    &.BLACK {
        color: $bt-black;
    }
    &.WHITE {
        color: $bt-white;
    }

    &.mdi {
        line-height: 0.75em !important;
    }

    &.fa {
        font-size: 20px;
        line-height: 1.2em !important;
    }
}

.mat-icon.icon-small,
.icon-small + .mat-icon {
    font-size: 18px;
    height: 18px;
    width: 18px;

    &.fa {
        font-size: 16px;
    }
}

.mat-icon.icon-large,
.icon-large + .mat-icon {
    font-size: 36px;
    height: 36px;
    width: 36px;

    &.mdi {
        font-size: 37px;
    }

    &.fa {
        font-size: 30px;
    }
}

.mat-icon-button .mat-icon.icon-small,
.mat-icon-button .icon-small + .mat-icon {
    height: 24px;

    &.mdi,
    &.fa {
        height: 16px;
    }
}

.mat-icon-button .mat-icon.icon-large,
.mat-icon-button .icon-large + .mat-icon {
    height: 24px;

    &.mdi,
    &.fa {
        height: 36px;
    }
}
