@import 'src/variables';

// *************
// Left side panel
// *************
.hap-left-side-panel {
    border-right: 1px solid $grey-7;
}

// *************
// mat-drawer
// *************
mat-drawer-container.mat-drawer-hap {
    .mat-drawer {
        width: 95%;
        transition: all 0.6s ease-in-out;
        border-right: 1px solid $grey-7;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    }
    mat-drawer {
        background-color: $main-background;
    }
}
