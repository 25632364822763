/****************************
 Variables
 ***************************/
@use '@angular/material' as mat;
// Variables issues du theme Angular Material

// Palettes Angular Material (primary, accent, warn)
$palette-primary: (
    50: #e7eef7,
    100: #c2d4ec,
    200: #9ab8df,
    300: #719bd2,
    400: #5285c9,
    500: #3470bf,
    600: #2f68b9,
    700: #275db1,
    800: #2153a9,
    900: #15419b,
    999: #042a3a,
    A100: #cfddff,
    A200: #9cb9ff,
    A400: #6995ff,
    A700: #4f83ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$palette-accent: (
    50: #ffeee9,
    100: #ffd5c8,
    200: #ffb9a3,
    300: #ff9c7e,
    400: #ff8763,
    500: #ff7247,
    600: #ff6a40,
    700: #ff5f37,
    800: #ff552f,
    900: #ff4220,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffd6cf,
    A700: #ffbfb6,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$palette-warn: (
    50: #fae8e8,
    100: #f2c6c6,
    200: #e9a0a0,
    300: #e07a7a,
    400: #d95e5e,
    500: #d24141,
    600: #cd3b3b,
    700: #c73232,
    800: #c12a2a,
    900: #b61c1c,
    A100: #ffecec,
    A200: #ffb9b9,
    A400: #ff8686,
    A700: #ff6d6d,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

// Couleurs Angular Material (primary, accent, warn)
$color-primary-extra-dark: mat.get-color-from-palette($palette-primary, 999);
$color-primary-dark: mat.get-color-from-palette($palette-primary, 900);
$color-primary: mat.get-color-from-palette($palette-primary, 500);
$color-primary-light: mat.get-color-from-palette($palette-primary, 300);
$color-primary-extra-light: mat.get-color-from-palette($palette-primary, 100);
$color-primary-lightest: mat.get-color-from-palette($palette-primary, 50);

$color-primary-999: mat.get-color-from-palette($palette-primary, 999);
$color-primary-900: mat.get-color-from-palette($palette-primary, 900);
$color-primary-800: mat.get-color-from-palette($palette-primary, 800);
$color-primary-700: mat.get-color-from-palette($palette-primary, 700);
$color-primary-600: mat.get-color-from-palette($palette-primary, 600);
$color-primary-500: mat.get-color-from-palette($palette-primary, 500);
$color-primary-400: mat.get-color-from-palette($palette-primary, 400);
$color-primary-300: mat.get-color-from-palette($palette-primary, 300);
$color-primary-200: mat.get-color-from-palette($palette-primary, 200);
$color-primary-100: mat.get-color-from-palette($palette-primary, 100);
$color-primary-50: mat.get-color-from-palette($palette-primary, 50);

$color-accent-dark: mat.get-color-from-palette($palette-accent, 900);
$color-accent: mat.get-color-from-palette($palette-accent, 500);
$color-accent-light: mat.get-color-from-palette($palette-accent, 300);
$color-accent-lightest: mat.get-color-from-palette($palette-accent, 50);

$color-warn: mat.get-color-from-palette($palette-warn, 500);
$color-warn-lightest: mat.get-color-from-palette($palette-warn, 50);

// Autres couleurs
$color-grey-extra-dark: #4b4b4b;
$color-grey-dark: #808080;
$color-grey: #a6a6a6;
$color-grey-light: #bfbfbf;
$color-grey-extra-light: #e6e6e6;
$color-grey-lightest: #f5f5f5;

$color-selection: #c7d38b;
$color-hover: #e6f4b7;

$color-success: #89c64d;

$color-attention: #ffce47;

$color-info: #47d4ff;

// Autres variables
$space-between-buttons: 8px;

/****************************
 Redéfinition du thème spécifique à l'application de test
 ***************************/
$palette-primary: mat.define-palette($palette-primary);
$palette-accent: mat.define-palette($palette-accent);
$palette-warn: mat.define-palette($palette-warn);

// Dégradés de gris
$dark: #535354;
$medium: #989aa2;
$light: #dbdbdb;
$ultralight: #f8f8f8;
$grey-1: #f5f5f5;
$grey-2: #e4e4e4;
$grey-3: #cccccc;
$grey-4: #d0d0d0;
$grey-5: #4b555f;
$grey-6: #404040;
$grey-7: #cbc8c8;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        dark: $dark,
        medium: $medium,
        light: $light,
        ultralight: $ultralight,
        grey-1: $grey-1,
        grey-2: $grey-2,
        grey-3: $grey-3,
        grey-4: $grey-4,
        grey-5: $grey-5,
        grey-6: $grey-6,
        grey-7: $grey-7,
    ),
    $theme-colors
);
// Couleurs de fond non-matérial
$main-background: #f7f7f7;
$top-bar-active-background: #eaeff4;
$top-bar-inactive-color: #5363db;

// Statuts
$success: #6dc911;
$error: #ff0000;
$warn: #fd7313;

// Message
$error-text-color: #cc1d1d;
$error-bg-color: rgba(#ec3d3e, 0.3);

// Button
$bt-darker-blue: #009dff;
$bt-lighter-blue: #2caeff;
$bt-green: #6dc911;
$bt-red: #ff0000;
$bt-orange: #fd7313;
$bt-blue: #0066ff;
$bt-yellow: #f5c153;
$bt-grey: $medium;
$bt-black: black;
$bt-white: white;

// Variables issues du theme Angular Material

/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Theme Config
$primary-main-color: #2c3574;
$primary-lighter-color: #c0c2d5;
$primary-darker-color: #1b2257;
$primary-200-color: #2c3574;

$accent-main-color: #009dff;
$accent-lighter-color: #b3e2ff;
$accent-darker-color: #0081ff;
$accent-200-color: #009dff;

$warn-main-color: #ff0000;
$warn-lighter-color: #ffb3b3;
$warn-darker-color: #ff0000;
$warn-200-color: #ff0000;

$action-main-color: #fde002;

$green-1: #549d0b;
// $grey-1: #404040;
// $grey-2: #e9e9e9;
$blue-1: #2d3670;

$color-tool-section-divider: $primary-lighter-color;
// This variable affects the `.h-*` and `.w-*` classes.

$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
    (
        5: 5%,
        10: 10%,
        15: 15%,
        20: calc(1 / 5) * 100%,
        25: calc(1 / 4) * 100%,
        30: 30%,
        33: calc(1 / 3) * 100%,
        50: calc(1 / 2) * 100%,
        67: calc(2 / 3) * 100%,
        70: 70%,
        75: calc(3 / 4) * 100%,
        80: calc(4 / 5) * 100%,
        85: 85%,
        90: 90%,
        95: 95%,
        100: 100%,
        auto: auto,
    ),
    $sizes
);
