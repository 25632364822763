// **************************
// Custom utilities class
// **************************
// Note that every utility class must specify 'important' keyword

.c-pointer {
    cursor: pointer !important;
}

.c-default {
    cursor: default !important;
}

.c-grabbable {
    cursor: grab !important;

    &.c-pointer {
        // S'il y a aussi la class c-pointer, on laisse le cursor pointer par défaut
        cursor: pointer !important;
    }

    &:active {
        cursor: grabbing !important;
    }
}

.c-help {
    cursor: help !important;
}

.ws-preline {
    white-space: pre-line !important;
}

.text-small {
    font-size: 14px !important;
}

.text-xsmall {
    font-size: 12px !important;
}

.text-big {
    font-size: 20px !important;
}

.bold {
    font-weight: bold !important;
}

.opacity-1 {
    opacity: 1 !important;
}

.no-border-radius {
    border-radius: 0 !important;
}

// Width 0
.w-0 {
    width: 0 !important;
}

// Height 0
.h-0 {
    height: 0 !important;
}

// Class to add to Bootstrap columl (col) to give it full width
.full-width-col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

// Icon along text
.icon-before-text {
    position: relative;
    top: 5px;
    margin-right: 0.5rem;
}

// Overflow-wrap: anywhere (= word-break: normal + overflow-wrap: anywhere)
.text-break-anywhere {
    word-break: break-word;
}

// Object-fit properties
.object-fit-cover {
    object-fit: cover !important;
}

.object-fit-contain {
    object-fit: contain !important;
}

.object-fit-fill {
    object-fit: fill !important;
}

.object-fit-none {
    object-fit: none !important;
}

.object-fit-scale-down {
    object-fit: scale-down !important;
}

// Row & column gap properties
@each $size, $length in $spacers {
    .row-gap-#{$size} {
        row-gap: #{$length} !important;
    }
    .column-gap-#{$size} {
        column-gap: #{$length} !important;
    }
}
