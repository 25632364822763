@import 'src/variables';

// ********************************************************
//  Surchage des mat-form-field pour les éléments outline
// ********************************************************
mat-form-field.form-custom {
    .mat-form-field-outline {
        .mat-form-field-outline-start {
            background-color: white;
        }
        .mat-form-field-outline-gap {
            background-color: white;
        }
        .mat-form-field-outline-end {
            background-color: white;
        }
    }
    .mat-form-field-infix {
        border-top-width: 0;
    }

    &.form-wrapper-pb0 {
        .mat-form-field-wrapper {
            padding-bottom: 0px;
        }
    }
}

mat-radio-group.form-custom {
    background-color: white;
    border: 1px $grey-2 solid;
    border-radius: 5px;

    mat-radio-button {
        border-bottom: 1px $grey-2 solid;
        padding: 4px 0px 4px 10px;

        &:last-child {
            border-bottom: 0;
        }

        label {
            margin-bottom: 0;
        }
    }
}

// ********************************************************
//  Customisation input color
// ********************************************************
input[type='color'] {
    appearance: none;
    border: none;
    width: 70px;
    height: 40px;
    background-color: transparent;
    padding: 0 0 0 0;
    cursor: pointer;

    &::-webkit-color-swatch {
        border-radius: 5px;
        border: 1px solid $grey-3;
    }

    &::-moz-color-swatch {
        border-radius: 5px;
        border: 1px solid $grey-3;
    }
}
