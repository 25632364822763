/****************************
 Mixins
 ***************************/
$spaces: (1, 2, 3, 5, 10, 15, 20, 30, 40, 60, 80);

@mixin space($size) {
    &:not(:last-child) {
        margin-bottom: $size;
    }
}

@mixin generate-spaces {
    @each $value in $spaces {
        .space-#{'' + $value} {
            @include space(#{$value}px);
        }
        .m-#{'' + $value} {
            margin: #{$value}px;
        }
        .mx-#{'' + $value} {
            margin-left: #{$value}px;
            margin-right: #{$value}px;
        }
        .my-#{'' + $value} {
            margin-top: #{$value}px;
            margin-bottom: #{$value}px;
        }
        .mt-#{'' + $value} {
            margin-top: #{$value}px;
        }
        .mr-#{'' + $value} {
            margin-right: #{$value}px;
        }
        .mb-#{'' + $value} {
            margin-bottom: #{$value}px;
        }
        .ml-#{'' + $value} {
            margin-left: #{$value}px;
        }
        .p-#{'' + $value} {
            padding: #{$value}px;
        }
        .px-#{'' + $value} {
            padding-left: #{$value}px;
            padding-right: #{$value}px;
        }
        .py-#{'' + $value} {
            padding-top: #{$value}px;
            padding-bottom: #{$value}px;
        }
        .pt-#{'' + $value} {
            padding-top: #{$value}px;
        }
        .pr-#{'' + $value} {
            padding-right: #{$value}px;
        }
        .pb-#{'' + $value} {
            padding-bottom: #{$value}px;
        }
        .pl-#{'' + $value} {
            padding-left: #{$value}px;
        }
    }
}

/*
 * Common Global Mixins
 * ----------------------------------------------------------------------------
 */

// Mat-card avec image
@mixin mat-card-with-image($card-dimension) {
    .mat-card {
        height: $card-dimension;
    }

    .mat-card-image,
    .img-placeholder {
        height: $card-dimension;
        width: $card-dimension;
        min-width: $card-dimension;
        max-width: $card-dimension;
        overflow: visible;
        margin: 0 16px 0 -16px;
    }

    .mat-card-image {
        object-fit: cover;
        margin-top: -16px;
        border-radius: unset !important;
    }

    .img-placeholder {
        background: #cccccc;
        position: relative;
        top: -16px;

        .mat-icon {
            height: 24px;
            width: 24px;
            position: relative;
            top: calc((#{$card-dimension} / 2) - 12px);
            left: calc((#{$card-dimension} / 2) - 12px);
            color: #999999;
            transform: scale(3);
        }
    }
}

// Scrollable
@mixin scrollable-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

@mixin scrollable-content {
    overflow: auto;
}
