// **************************
// HTML & body
// **************************

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    touch-action: manipulation;
}
