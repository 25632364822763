@import 'src/variables';

$color-tool-button: $primary-main-color;
$color-tool-button-disabled: $color-grey-dark;
$color-separator-tool-group: $color-primary-extra-dark;
$color-hover-tool-button: $color-primary-lightest;
$color-separator-tool-group: #054597;
$color-background-tools: white;
$color-label-active: $primary-main-color;
$color-options-tool-panel: white;
$color-tool-section-divider: #3470bf;

// TODO DGO voir si tj utilisé
@mixin colorizeDisabledImg {
    filter: invert(82%) sepia(0%) saturate(223%) hue-rotate(135deg) brightness(88%) contrast(79%);
}

@mixin colorizeToolIcon {
    filter: invert(99%) sepia(30%) saturate(2%) hue-rotate(328deg) brightness(109%) contrast(100%);
}
@mixin colorizeToolIconDisabled {
    filter: invert(82%) sepia(0%) saturate(223%) hue-rotate(135deg) brightness(88%) contrast(79%);
}
@mixin colorizeToolIconPrimary {
    filter: invert(58%) sepia(25%) saturate(946%) hue-rotate(143deg) brightness(86%) contrast(91%);
}
@mixin colorizeToolIconDisabled {
    filter: grayscale(100%) saturate(0.5) opacity(0.5);
}

@mixin colorizeToolIcon {
    // **************************
    // Redéfinition du thème spécifique à l'application de test
    // **************************
    // Filtre pour donners aux icônes images la même couleur que $color-primary
    // https://codepen.io/sosuke/pen/Pjoqqp
    filter: invert(38%) sepia(24%) saturate(2921%) hue-rotate(187deg) brightness(91%) contrast(81%);
}

@mixin colorizeToolIconPrimary() {
    filter: invert(38%) sepia(24%) saturate(2921%) hue-rotate(187deg) brightness(91%) contrast(81%);
}

@mixin colorizeBlackToWhite() {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(5deg) brightness(102%) contrast(102%);
}
@mixin colorizeBlackToSuccess() {
    filter: invert(64%) sepia(74%) saturate(1087%) hue-rotate(44deg) brightness(98%) contrast(87%);
}
@mixin colorizeBlackToWarn() {
    filter: invert(17%) sepia(73%) saturate(6137%) hue-rotate(356deg) brightness(101%) contrast(122%);
}
@mixin colorizeBlackToAccent() {
    filter: invert(53%) sepia(53%) saturate(5828%) hue-rotate(181deg) brightness(103%) contrast(105%);
}
@mixin colorizeBlackToPrimary() {
    filter: invert(19%) sepia(53%) saturate(1066%) hue-rotate(201deg) brightness(95%) contrast(97%);
}
@mixin colorizeBlackToDisabled() {
    filter: invert(81%) sepia(1%) saturate(1911%) hue-rotate(190deg) brightness(77%) contrast(85%);
}

.mode-options-current-icon {
    color: $color-primary;
}

.mat-button-toggle-label-content > * {
    line-height: 16px !important;
}

.tools-menu-subgroup {
    width: 135px !important;
}

.cnmap-api-entry .mode-options-others-entry-selected {
    color: $primary-main-color !important;
    background-color: lightgrey;
}
