/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 */
@use '@angular/material' as mat;
// **************************
// Boostrap
// **************************

@import 'variables';

// **************************
// Angular Theme
// **************************
// NB : Variables are included in material theme
@import 'theme/material';

// Functions, variables, and mixins that are needed by other Bootstrap files
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
// Bootstrap Reboot
@import '~bootstrap/scss/reboot';
// Boostrap Grid
@import '~bootstrap/scss/grid';
//Boostrap Utilities
@import '~bootstrap/scss/utilities';

// **************************
// Common styles
// **************************
@import 'cnmap-default-theme';
@import 'icons';
@import 'layout';
// @import 'material_customization';
@import 'typography';
@import 'utilities';
@import 'theme/material';
@import '~@acenv/cnmap-angular-editor-lib/global-standalone';
// FIX global-standalone
.cnmap-api-entry .primary,
.cnmap-dialog .primary {
    color: $primary-main-color !important;
}

@import 'styles/global-old-cnmap';
// **************************
// Angular calendar
// **************************
@import '../node_modules/angular-calendar/css/angular-calendar.css';

// **************************
// CN-MAP Editor CSS
// **************************
@import '~@acenv/cnmap-editor/cn-map/cn_map_svg.css';

// **************************
// Mixins & variables
// **************************
@import 'mixins';

// **************************
// Editor map Theme
// **************************
// NB : Variables are included in editor theme
@import 'theme/editor';

// **************************
// Matrial Theme
// **************************
@import '@angular/material/theming';

// **************************
// Customize Angular Material
// **************************

// Define a custom typography config that overrides the font-family as well as the
// (https://material.angular.io/guide/typography)
// `headlines` and `body-1` levels.
$custom-typography: mat-typography-config(
    $font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $headline: mat-typography-level(32px, 48px, 700),
    $body-1: mat-typography-level(16px, 24px, 500),
);
// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($custom-typography);

// **************************
// Imports des styles customs
// **************************
@import 'styles/components/form';

// **************************************
// Imports des styles common des modules
// **************************************
@import 'styles/modules/hap-common';

// Dialog
.fullscreen-dialog .mat-dialog-container {
    border-radius: 0 !important;
    padding: 0 !important;
}

.fullscreen-dialog .fullscreen-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.fullscreen-dialog .fullscreen-content .header {
    display: flex;
    height: 48px;
    width: 100%;
    align-items: center;
    background: $primary-main-color;
    color: white;

    .title {
        flex-grow: 1;
        font-size: 18px;
        font-weight: bold;
        margin-left: 1.5rem;
    }

    .close-button {
        background: $accent-main-color;
        border-radius: 0;
        width: 48px;
        height: 48px;
        min-width: 48px;

        .mat-icon {
            margin-right: 0;
        }
    }
}

.fullscreen-dialog .fullscreen-content .content {
    flex-grow: 1;
    margin-right: 0;
    margin-left: 0;
}

.fullscreen-dialog .fullscreen-content .floating-buttons-container {
    padding: 20px;
    background-color: rgb(0, 0, 0, 0);

    button + button {
        margin-left: 10px;
    }
}

.minwidth600-dialog .mat-dialog-container {
    min-width: 600px;
}

.minwidth700-dialog .mat-dialog-container {
    min-width: 700px;
}

.minwidth1000-dialog .mat-dialog-container {
    min-width: 1000px;
    max-width: 1000px;
}

.mat-dialog-container {
    padding: 0 !important;
    min-width: 300px;

    .mat-dialog-title {
        padding: 0 0 0 8px;
        color: white !important;
        background: $primary-main-color;
        margin-bottom: 24px;
        font-size: 16px;
    }

    .mat-dialog-content {
        margin: 0 !important;
        padding-bottom: 24px;
        max-height: 80vh;
    }

    .mat-dialog-actions {
        box-sizing: content-box;
        border-top: 1px solid $primary-lighter-color;
        padding: 8px 20px !important;
        margin-bottom: 0 !important;
        margin-top: 10px;

        button {
            text-transform: uppercase !important;
        }
    }
}

// Forms
// -> personnalisation de l'apparence "fill"
.mat-form-field .mat-form-field-flex {
    background-color: initial !important; // pas de couleur de fond
    padding-top: 0 !important; // moins d'espace entre les champs
}

.white-search-form-field {
    .mat-form-field-outline {
        background-color: white;
    }
}

.small-search-form-field {
    .mat-form-field-infix {
        padding: 0.5em;
        border-top: 0;
    }
    .mat-form-field-prefix {
        top: 0.5em !important;
    }
    .mat-form-field-label-wrapper {
        padding-top: 0;
    }
    .mat-form-field-label-wrapper {
        top: 0;
        padding-top: 0;
    }
    .mat-form-field-wrapper {
        padding: 0;
    }
}

// -> Pour les inputs + textarea avec border radius
.mat-border-radius {
    border: 1px solid #dbdbdb;
    border-radius: 7px;
    background: white;

    &.mat-textarea {
        padding: 15px 5px;
    }
}

// -> police en noir si readonly (même si disabled)
input.mat-input-element[readonly='true'] {
    color: $dark-text;
}

.big-increment {
    [readonly] {
        pointer-events: none;
    }
}

mat-checkbox.readonly,
div.readonly {
    pointer-events: none !important;
    cursor: not-allowed;
}

.mat-input-element[readonly='true'],//:not([type='number']),
input[readonly]:not([type='number']),
textarea[readonly]:not([type='number']) {
    cursor: default;
    background-color: $input-disabled-bg;
    opacity: 0.4;
}

.mat-input-readonly {
    .mat-form-field-infix {
        padding-bottom: 0;
    }

    .mat-form-field-underline {
        background: none;
    }
}

.mat-input-element:disabled {
    color: black;
}

.mat-select.readonly {
    span {
        color: $dark-text;
    }
}

// prefix
.input-with-prefix {
    .mat-form-field-outline {
        background: white;
    }

    .mat-form-field-prefix {
        top: 0.5em !important;
    }
}

.chips {
    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-form-field-infix {
        border-top: none;
    }
}

// Custom select
.small-select {
    .mat-form-field-infix {
        width: 120px;
        font-size: 10px;
    }
}

.custom-select-container {
    .readonly {
        span {
            color: $dark-text;
        }
    }
}

// Tabs
.mat-tab-label,
.mat-tab-link {
    text-decoration: none !important;
    height: 64px !important;
    font-size: 20px !important;
    opacity: 1 !important;
}

// Buttons
button {
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 22px !important;
    padding: 4px 8px !important;

    &.small-button {
        height: 24px;
        width: 24px;
        line-height: 24px !important;
        padding: 0 !important;
    }

    .mat-list-item:disabled {
        // Bouton "disabled" dans une liste
        cursor: default;
        color: $medium !important;

        &:hover {
            background-color: inherit !important;
        }

        .mat-icon.mat-primary,
        .mat-icon.mat-warn {
            color: $medium !important;
        }
    }
}

.mat-icon-button {
    &.mat-grey {
        color: $light;
    }

    &.mat-justify {
        color: $warn;
    }

    &.primary[disabled] {
        color: $medium !important;
    }

    &.warn[disabled] {
        color: $medium !important;
    }
}

.mat-raised-button {
    &.mat-black {
        background-color: $grey-6;
        color: white;
    }

    &.mat-accent {
        color: white;
    }

    &.mat-grey {
        background-color: $light;
    }

    &.mat-yellow {
        background-color: $action-main-color;
    }

    &.mat-success {
        color: white;
        background-color: $success;

        &[disabled] {
            color: white;
            background-color: $success;
        }
    }

    &.mat-orange {
        color: white;
        background-color: $warn;

        &[disabled] {
            color: white;
            background-color: rgba(0, 0, 0, 0.26);
        }
    }
}

// Buttons containers
.floating-buttons-container {
    position: fixed;
    background-color: $ultralight;
    z-index: 10;

    &.bottom-right {
        bottom: 20px;
        right: 20px;
    }

    &.with-spacing {
        border: 1px solid $light;
        border-radius: 5px;

        .mat-button-base {
            margin: 4px;
        }
    }
}

.margin-for-floating-buttons-bottom {
    margin-bottom: 50px;
}

.button-group {
    top: 0;
    right: 0;
    box-shadow: none;
    border-radius: 0 !important;
    color: white;
    background: $light;
    z-index: 1;
}

// button-toggle
.mat-button-toggle-group {
    border-radius: 10px !important;
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 22px !important;
    padding: 4px 8px !important;
}

.mat-button-toggle-label-content {
    line-height: 22px !important;
}

.mat-button-toggle {
    background-color: white !important;
    border: 1px solid $light !important;
    color: $primary-main-color !important;

    &.green-toggle {
        color: #509010;
        background: #e2f4cf;
    }

    &.red-toggle {
        color: #a72222;
        background: #fbc2c2;
    }

    &.blue-toggle {
        color: $primary-main-color;
        background: #cddcea;
    }

    &.purple-toggle {
        color: #4a58b7;
        background: #d3d9ff;
    }

    &.disabled-toggle {
        background-color: rgba(44, 44, 44, 0.12) !important;
        color: rgba(0, 0, 0, 0.26) !important;
    }
}

.mat-button-toggle-checked {
    border: 1px solid $light !important;
    background-color: $primary-main-color !important;
    color: white !important;

    &.green-toggle {
        color: white !important;
        background-color: #509010 !important;
    }

    &.red-toggle {
        color: white !important;
        background-color: #a72222 !important;
    }

    &.blue-toggle {
        color: white !important;
        background: $primary-main-color !important;
    }

    &.purple-toggle {
        color: white !important;
        background: #4a58b7 !important;
    }
}

.sidebar-button {
    .mat-button-toggle-group {
        padding: 8px !important;

        .mat-button-toggle {
            border: none !important;

            .mat-button-toggle-label-content {
                padding: 0;

                mat-icon {
                    margin: 5px 0 5px 0;
                }
            }
        }

        .mat-button-toggle-checked {
            background-color: white !important;
            color: $accent-main-color !important;
        }
    }

    .control-group {
        .control-button {
            min-width: 20px;

            mat-icon {
                margin: 5px 0 5px 0;
            }
        }
    }
}

.mat-button-toggle-disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: #e1e1e1;
}

// Action cards buttons
.mat-card.action-card {
    background-color: unset;
    border-style: dashed;
    border-width: 2px;
    border-radius: 10px;
    border-color: $dark;
    color: $dark;
    box-shadow: none !important;
    padding: 0;

    &.disabled {
        border-color: $medium !important;
    }

    button:not(.no-padding) {
        padding: 40px 0 !important;

        mat-icon {
            margin-bottom: 8px;
        }
    }
}

// Lists
.mat-list-text h1 {
    font-size: 24px !important;
}

.mat-list-item.disabled {
    background-color: $ultralight;

    .mat-list-text {
        color: $medium;
    }
}

// Checkboxes
.mat-checkbox {
    &:not(.square-check-box) {
        padding-left: 12px; // Laisse l'espace pour que le ripple qui apparaît lorsqu'on clique puisse apparaître entièrement

        .mat-checkbox-background,
        .mat-checkbox-frame {
            border-radius: 70% !important;
        }

        &.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
            background-color: $action-main-color !important;
        }

        &.mat-checkbox-checked.mat-accent .mat-checkbox-background,
        .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
            background-color: $action-main-color;
        }
    }

    &.no-label {
        .mat-checkbox-layout {
            margin-bottom: 0;
        }
    }
}

.default-checkbox {
    .mat-checkbox {
        .mat-checkbox-background,
        .mat-checkbox-frame {
            border-radius: 0 !important;
        }
    }
}

// Cards

.mat-card-content {
    position: relative; // Permet de positionner correctement des mat-divider (qui sont en position absolute) dans les mat-card

    .card-title {
        font-weight: 500;
        font-size: 20px;
        color: $primary-main-color;
        line-height: 40px;
    }

    .card-subtitle-bold {
        font-weight: bold;
        font-size: 16px;
        color: $dark;
    }

    .card-subtitle-grey {
        font-weight: bold;
        font-size: 16px;
        color: $medium;
    }

    .card-text {
        font-size: 14px;
        color: $dark;
    }
}

.mat-card {
    overflow: hidden;

    .mat-error .form-error {
        margin: 0 -8px -8px -8px;

        &.middle-error {
            margin: 0 -16px;
        }
    }

    &:not([class*='mat-elevation-z']) {
        box-shadow: 0 3px 6px #00000029;
    }
}

.small-card {
    width: 49%;
}

.equipment-card {
    background: $main-background;
    border-radius: 3px;
    border-left: 2px solid $medium;
    height: 40px;

    &.room {
        background: $light;
    }
}

// **************************
// Customize Enerbim Commons
// **************************

// Notifications
.mat-snack-bar-container {
    &.notification-info {
        color: $light-text;
        background-color: $primary-darker-color;
    }

    &.notification-success {
        color: $light-text;
        background-color: $success;
    }

    &.notification-warn {
        color: $light-text;
        background-color: $warn;
    }

    &.notification-error {
        color: $light-text;
        background-color: $warn-main-color;
    }

    .notification-action {
        color: $accent-main-color;
    }
}

lib-users-list {
    // Donne le même padding latéral qu'aux autres écrans
    display: block;
    padding-left: 30px;
    padding-right: 30px;

    // Donne le même fond qu'aux autres écrans
    .mat-table,
    .mat-paginator {
        background-color: #fafafa;
    }
}

// Grey spinner
.mat-progress-spinner.grey circle,
.mat-spinner.grey circle {
    stroke: $medium !important;
}

// Increment input sizes
// -> Minimal size
.tiny-increment {
    .mat-form-field-infix {
        width: 16px;
    }

    .mat-icon-button {
        margin: 0 !important;
    }
}

// -> Small size
.small-increment {
    .mat-form-field-infix {
        width: 40px;
    }
}

// **************************
// Global styles
// **************************

.white-background {
    background: white;
}

// Empêche l'apparition automatique de chevrons dans la navbar

// Main sides padding
.main-padding-sides {
    padding-left: 30px;
    padding-right: 30px;
}

// Background color button
.bg-btn-grey-1 {
    background-color: $grey-1 !important;
    color: $grey-6 !important;
}

.bg-btn-grey-5 {
    background-color: $grey-5 !important;
}

// Text colors
.primary {
    color: $primary-main-color !important;
}

.error {
    color: $error !important;
}

.accent {
    color: $accent-main-color !important;
}

.grey-1 {
    color: $grey-1 !important;
}

.grey-5 {
    color: $grey-5 !important;
}

.white {
    color: white !important;
}

.warn {
    color: $warn-main-color !important;
}

.justify {
    color: $warn;
}

.success {
    color: $success !important;
}

.color-deleted {
    color: #a52a2a !important;
}

.medium {
    color: $medium !important;
}

.dark {
    color: $dark !important;
}

.yellow {
    color: $action-main-color;
}

// Background colors
.main-background {
    background: $main-background;
}

.accent-background {
    background: $accent-main-color !important;
}

// Selection
.mat-list-item.selected,
.tree-item-container.selected,
.mat-card.selected,
tr.selected,
tr.selected .mat-cell {
    background-color: $primary-main-color;
    color: white;
}

.accent-list.selected {
    background: $accent-200-color;
    color: inherit;
}

.no-background-color-list.selected {
    background: inherit;
    color: inherit;
}

.mat-card.selected {
    background-color: $primary-main-color !important; // Pour forcer, car la couleur de base est déjà en important
}

// Scrollable
.scrollable-container {
    @include scrollable-container;
}

.scrollable-content {
    @include scrollable-content;
    border-top: 1px transparent;
    transition: border-top 0.2s linear;
}

.scrollable-content.scrolled {
    border-top: 1px solid #aaaaaa;
    transition: border-top 0.2s linear;
}

// Placeholders
.placeholder {
    display: block;

    &.field {
        width: 180px;
    }

    &.increment {
        width: 96px;
    }

    &.comment {
        width: 85px;
    }

    &.comment-autre {
        width: 90px;
    }

    &.comment-point-de-controle {
        width: 45px;
    }
}

.diagnostic-section-border-placeholder {
    display: block;
    width: 6px;
}

// Accordions
.equipment-accordion {
    mat-expansion-panel-header {
        padding: 0 8px;
    }

    mat-expansion-panel {
        border-radius: 0 !important;
        box-shadow: none !important;
        border-bottom: 1px solid black;
    }

    .mat-expansion-panel-body {
        padding: 0;
    }
}

.description-accordion {
    mat-expansion-panel-header {
        padding: 0 8px;
    }

    mat-panel-description {
        cursor: default !important;
    }

    mat-expansion-panel {
        box-shadow: none !important;
    }

    .mat-expansion-panel-body {
        padding: 0;
    }

    .mat-expansion-panel-header.mat-expansion-toggle-indicator-before .mat-expansion-indicator {
        margin-left: 30px !important;
    }
}

// Sections status
.point-de-controle-conforme {
    border-left: 6px solid $success;
}

.point-de-controle-non-conforme {
    border-left: 6px solid $error;
}

.point-de-controle-justifier {
    border-left: 6px solid $warn;
}

.point-de-controle-non-rempli {
    border-left: 6px solid $medium;
    color: $warn;
    font-weight: bold;
}

.point-de-controle {
    border-left: 6px solid $medium;
}

.diagnostic-section-primary {
    border-left: 2px solid $primary-main-color;
}

// Conformité sur chips
.chips-conformite {
    background-color: white !important;
    border: 3px solid;

    &.point-de-controle-conforme-chip {
        border-color: $success;
    }

    &.point-de-controle-non-conforme-chip {
        border-color: $error;
    }

    &.point-de-controle-justifier-chip {
        border-color: $warn;
    }

    &.point-de-controle-non-rempli-chip {
        border-color: $medium;
    }
}

// Contacts sections
.contact-section-ok {
    border: 2px solid $success;
}

.contact-section-ko {
    border: 2px solid $error;
}

.contact-section-empty {
    border: 2px solid $medium;
}

.underlined {
    border-bottom: 1px solid $light;
}

.overlined {
    border-top: 1px solid $light;
}

// Required & errors
.required {
    //top: -0.5em;
    color: red;
}

.has-required {
    background: $light;
    color: $medium;
    font-weight: bold;
    padding: 5px;
}

.to-justify {
    background: #fd731330;
    color: $warn;
    font-weight: bold;
    padding: 5px;
}

.has-error {
    border-bottom: 2px solid red;
}

.error-div {
    background: red;
    color: white;
}

// Progress bar
.todo-progress-bar {
    .mat-progress-bar {
        height: 6px;
    }

    mat-progress-bar .mat-progress-bar-buffer {
        background: $grey-2;
    }

    .mat-progress-bar-fill::after {
        background-color: $action-main-color;
    }
}

.toolbar {
    z-index: 2 !important;

    .mat-toolbar {
        border-bottom: solid $dark-background 1px;
        background-color: $light-background;
        height: 45px;
    }
}

// *********************
// AGENDA
// *********************
// Semaine
.cal-week-view {
    border: none !important;

    .cal-day-headers {
        border: none !important;
    }

    .cal-hour-segment {
        border: none !important;
    }

    .cal-hour-odd {
        background: none !important;
        border-top: 1px solid $light !important;
        border-bottom: 1px solid $light !important;
    }

    .cal-today {
        background: none !important;
        color: $accent-main-color !important;
    }

    .cal-all-day-events {
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
    }

    .cal-time-events {
        border: none !important;
    }

    .cal-event {
        color: black !important;
        background: $main-background !important;
        border: 1px solid $medium !important;
        border-left: 8px solid $accent-main-color !important;
        border-radius: 5px !important;
    }
}

// Jour
.cal-day-view .cal-current-time-marker {
    margin-left: 0 !important;
    width: 100% !important;
}

// Mois
.cal-month-view {
    .cal-today {
        background: none !important;

        .cal-day-number {
            color: $accent-main-color !important;
        }

        &:hover {
            background: #ededed !important;
        }
    }

    .cal-cell-top {
        min-height: 0 !important;
    }

    .month-event {
        font-size: 12px;
    }
}

.drop-card-placeholder {
    background: $light;
    border-radius: 3px;
    height: 80px;
    font-style: italic;
}

.mesure-label {
    font-weight: bold;
    width: 200px;

    &.carrez,
    &.habitable {
        color: #509010;
    }

    &.hors-carrez,
    &.non-habitable,
    &.autre {
        color: #a72222;
    }

    &.au-sol,
    &.utile-brut {
        color: $primary-main-color;
    }

    &.utile-net {
        color: #4a58b7;
    }
}

.button-group-mesure {
    box-shadow: none;
    border-radius: 4px;
    background: $light;

    &.hide {
        visibility: hidden;
    }

    button {
        &.red-button {
            background: red;
            color: white;
        }

        &:after {
            content: '';
            height: 100%;
            width: 1px;
            position: absolute;
            right: 0;
            top: 0;
            background-color: white;
        }

        &:last-child:not(.zone) {
            border-radius: 0 4px 4px 0;

            &:after {
                width: 0;
            }
        }
    }
}

.mat-card-button {
    padding: 0 !important;
    width: 150px;
    height: 150px;

    .mat-card-content {
        width: 100%;
        height: 100%;

        div {
            width: 100%;
            height: 100%;

            button {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.statut-intervention {
    border-radius: 30px;
    padding: 3px 8px;
    font-size: 13px;
    color: white;
    vertical-align: bottom;
    white-space: nowrap;

    &.NON_DEMARREE {
        background-color: $medium;
    }

    &.ANNULEE,
    &.ANNULE {
        background-color: $error;
    }

    &.NON_REALISEE,
    &.NON_REALISE {
        background-color: $error;
    }

    &.EN_COURS,
    &.EN_ATTENTE {
        background-color: $bt-darker-blue;
    }

    &.TERMINEE,
    &.TERMINE {
        background-color: $success;
    }
}

.statut-workflow {
    border-radius: 30px;
    padding: 3px 8px;
    font-size: 13px;
    color: white;
    vertical-align: bottom;

    &.INACTIF {
        background-color: $warn-lighter-color !important;
    }

    &.ACTIF {
        background-color: $success !important;
    }

    &.consultation {
        width: 90px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.button-toggle-double {
    padding: 3px 8px;
    font-size: 13px;
    color: white;
    vertical-align: bottom;

    &.INACTIF {
        &.mat-button-toggle-checked {
            background-color: $warn-lighter-color !important;
        }
    }

    &.ACTIF {
        &.mat-button-toggle-checked {
            background-color: $success !important;
        }
    }

    &.left-toggle {
        border-radius: 30px 0 0 30px;
    }

    &.right-toggle {
        border-radius: 0 30px 30px 0;
    }
}

.mat-table {
    td.mat-cell:first-of-type {
        padding-left: 32px !important;
    }

    td {
        padding: 0 8px !important;
    }
}

.onglet-tab-admin {
    position: relative;
    background: $light;
    height: 40px;
    min-width: 240px;
    border-width: 4px 0 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.selected {
        background: white;
        color: $top-bar-inactive-color;

        &::before {
            border-color: transparent transparent transparent white;
        }
    }

    &::before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        right: -20px;
        border-style: solid;
        border-width: 40px 0 0 20px;
        border-color: transparent transparent transparent $light;
        cursor: pointer;
        z-index: 99999;
    }
}

.entete-tableau-admin {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    button {
        margin: 8px 0;
    }
}

.mat-cell,
.mat-footer-cell {
    font-size: 14px;
}

.mat-input-readonly {
    .mat-select-arrow {
        color: white !important;
    }

    .mat-form-field-underline {
        background: none !important;
    }
}

.document-error {
    background: $error-bg-color;
    color: $error-text-color;
    font-size: 13px;
    padding: 5px 15px;
}

.file-icon-container {
    position: relative;

    mat-icon {
        font-size: 36px;
        height: 36px;
        width: 36px;
        color: $dark;

        // Specific MIME types colors
        &.pdf {
            color: #d5272e;
        }

        &.doc,
        &.docx,
        &.odt {
            color: #2a5699;
        }

        &.xls,
        &.xlsx,
        &.ods {
            color: #1e6d42;
        }

        &.ppt,
        &.pptx,
        &.odp {
            color: #d04323;
        }

        &.jpg,
        &.jpeg,
        &.png {
            color: #1f8390;
        }

        &.bbp {
            color: #749e29;
        }
    }

    .file-extension {
        width: 36px;
        position: absolute;
        top: 11px;
        left: 0;
        color: white;
        font-size: 9px;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
    }
}

.piece-item {
    border-bottom: 1px solid $light;
    padding: 15px;
    color: $dark;
    font-weight: bold;
    font-size: 16px;

    &.piece-item-selected {
        color: white;
        background-color: $accent-main-color;

        &:hover {
            background: $accent-main-color;
            cursor: default;
        }
    }

    &:hover {
        cursor: pointer;
        background: $light;
    }

    & .piece-icon {
        width: 30px;
    }

    & .piece-title.piece-empty {
        opacity: 0.3;
    }

    &.hide {
        display: none !important;
    }
}

.room-name {
    color: $accent-main-color;
}

.item-clickable {
    cursor: pointer;
}

.item-underline {
    text-decoration: underline;
}

.required {
    color: inherit;

    &:after {
        content: ' *';
        color: red;
    }
}

// Filter to convert black color in images
// https://codepen.io/sosuke/pen/Pjoqqp
.img-primary {
    filter: invert(19%) sepia(9%) saturate(6340%) hue-rotate(200deg) brightness(103%) contrast(92%);
}

.img-disabled {
    filter: grayscale(100%) saturate(0.5) opacity(0.5);
}

.cndiad-form-field {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    color: rgba(0, 0, 0, 0.6);
    padding-bottom: 16px;
    margin-bottom: 17px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 0.9em;

    [ng-reflect-is-disabled='true'] {
        cursor: not-allowed;
    }
}

.button-selected {
    background-color: #ccc;
    border-radius: 0;
}

.elec-item-selected {
    background-color: $primary-main-color !important;
    color: white !important;
}

.card-selected {
    border: 2px solid $success;

    div.selected-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $success;
        color: white;
        width: 35px;
        height: 35px;
    }
}

// CSS pré-rapport
.block-title {
    color: white;
    font-size: 18px;
    font-weight: bold;
    background: $primary-main-color;
    text-transform: uppercase;
}

.block-subtitle {
    color: $grey-6;
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
}

.block-subtitle-2 {
    font-size: 18px;
    font-weight: bold;
    color: $primary-main-color;
}

.form-container {
    width: 50%;
}

/* Flex properties */

.f-direction-column {
    flex-direction: column;
}

.block-group {
    white-space: pre-line;

    .group-title {
        color: $primary-main-color;
        font-weight: bold;
        font-size: 17px;
    }

    .group-label {
        font-weight: bold;
        width: 35%;
    }

    .group-value {
        width: 65%;
    }

    .text-format {
        white-space: pre-line;
    }
}

app-form-control-error-message {
    font-size: 12px;
    font-weight: 400;
}

app-checkpoint-page .zoom-slider {
    height: calc(100vh - 300px) !important;
}

app-description-bien-page .zoom-slider {
    height: calc(100vh - 254px) !important;
}

.cnmap-dialog .mat-dialog-actions,
lib-confirm-dialog .mat-dialog-actions {
    flex-direction: row-reverse;

    .mat-button-base {
        margin-left: 8px;
    }
}

.cnmap-dialog .mat-form-field.w-100 {
    padding-left: 8px;
    padding-right: 8px;
}

/**
Display none sur le bouton d'aide qui renvoie vers un site EnerBim - DESC-COR-351
*/
/* désactivé car cela masque l'édition dans le menu du listing des interventions
.cnmap-menu button.mat-focus-indicator:nth-of-type(n + 3):nth-of-type(-n + 4) {
    display: none;
}
*/

.notification-error lib-notification > .align-items-center {
    align-items: flex-start !important;
}

/**
SCSS prélèvement selon les résultats d'analyse
 */
.marker_line.level3.result-level0,
.marker_line.level3.result-level0.exp {
    fill: none;
    stroke: #92d04f;
    stroke-width: 4px;
}

.marker_tail.level3.result-level0,
.marker_tail.level3.result-level0.exp {
    fill: #92d04f;
    stroke: #92d04f;
}

.marker_arrow.level3.result-level0,
.marker_arrow.level3.result-level0.exp {
    fill: #92d04f;
    stroke: #92d04f;
    stroke-width: 1px;
}

.marker_text.result-level0,
.marker_text.result-level0.exp {
    fill: #92d04f;
    stroke: #92d04f;
}

.marker_shape.level1.result-level0,
.marker_shape.level1.result-level0.exp {
    fill: #92d04f !important;
    stroke: #92d04f !important;
}

.marker_line.level3.result-level1,
.marker_line.level3.result-level1.exp {
    fill: none;
    stroke: #c6bc9f;
    stroke-width: 4px;
}

.marker_tail.level3.result-level1,
.marker_tail.level3.result-level1.exp {
    fill: #c6bc9f;
    stroke: #c6bc9f;
}

.marker_arrow.level3.result-level1,
.marker_arrow.level3.result-level1.exp {
    fill: #c6bc9f;
    stroke: #c6bc9f;
    stroke-width: 1px;
}

.marker_shape.level1.result-level1,
.marker_shape.level1.result-level1.exp {
    fill: #c6bc9f !important;
    stroke: #c6bc9f !important;
}

.marker_text.result-level1,
.marker_text.result-level1.exp {
    fill: #c6bc9f;
    stroke: #c6bc9f;
}

.marker_line.level3.result-level2,
.marker_line.level3.result-level2.exp {
    fill: none;
    stroke: #ffc130;
    stroke-width: 4px;
}

.marker_tail.level3.result-level2,
.marker_tail.level3.result-level2.exp {
    fill: #ffc130;
    stroke: #ffc130;
}

.marker_arrow.level3.result-level2,
.marker_arrow.level3.result-level2.exp {
    fill: #ffc130;
    stroke: #ffc130;
    stroke-width: 1px;
}

.marker_text.result-level2,
.marker_text.result-level2.exp {
    fill: #ffc130;
    stroke: #ffc130;
}

.marker_shape.level1.result-level2,
.marker_shape.level1.result-level2.exp {
    fill: #ffc130 !important;
    stroke: #ffc130 !important;
}

.marker_line.level3.result-level3,
.marker_line.level3.result-level3.exp {
    fill: none;
    stroke: #bf0018;
    stroke-width: 4px;
}

.marker_tail.level3.result-level3,
.marker_tail.level3.result-level3.exp {
    fill: #bf0018;
    stroke: #bf0018;
}

.marker_arrow.level3.result-level3,
.marker_arrow.level3.result-level3.exp {
    fill: #bf0018;
    stroke: #bf0018;
    stroke-width: 1px;
}

.marker_text.result-level3,
.marker_text.result-level3.exp {
    fill: #bf0018;
    stroke: #bf0018;
}

.marker_shape.level1.result-level3,
.marker_shape.level1.result-level3.exp {
    fill: #bf0018 !important;
    stroke: #bf0018 !important;
}

tr.mat-row:nth-child(even) {
    background: $ultralight;
}

span.mandatory {
    color: red;
}

.cdk-drag-preview {
    box-shadow: none;
    border: 1px dashed #000;
    background: rgba(0, 0, 0, 0.5);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.modal-overflow-hidden {
    .mat-dialog-container {
        overflow: hidden;
    }
}

.modal-transparent {
    .mat-dialog-title,
    > mat-dialog-container {
        background-color: transparent !important;
    }
}

.blurred-backdrop {
    backdrop-filter: blur(0.5rem);
}

.mat-label-like {
    color: rgba(0, 0, 0, 0.6);
}
