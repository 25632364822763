/**
 * Styles CSS pour l'éditeur de plan
 */

// color's variables
@import '../variables';

$bg-toggle-selected: #c7c7c7;

$font-size-button: 14px;
$font-size-menu: 16px;

@mixin btContent($fontWeight: 500, $lineHeight: 36px) {
    font-size: $font-size-button !important;
    font-weight: $fontWeight !important;
    padding: 0 16px !important;
    line-height: 36px !important;
}

app-editor-map {
    button {
        @include btContent();

        &.mat-icon-button {
            padding: 0 !important;
            line-height: 40px !important;
        }

        &.mat-menu-item {
            @include btContent(400, 48px);
        }
    }

    .mat-tab-label,
    .mat-tab-link {
        height: 41px !important;
    }

    .small-nav .mat-tab-link,
    .small-nav .mat-tab-label {
        padding: 0 12px;
        min-width: 80px;
        height: 36px !important;
        font-size: $font-size-menu !important;
    }

    .mat-button-toggle {
        &.mat-button-toggle-checked {
            background-color: $bg-toggle-selected !important;
            border: none !important;
        }

        button {
            padding: 0 !important;
        }
    }

    .mat-button-toggle-group {
        border-radius: 2px !important;
        padding: 0 !important;

        .mat-button-toggle {
            border: none !important;
            background-color: rgba(0, 0, 0, 0) !important;

            &.mat-button-toggle-checked {
                background-color: $bg-toggle-selected !important;
            }

            button {
                padding: 0 !important;

                .mat-button-toggle-label-content {
                    line-height: 36px !important;
                }
            }
        }
    }

    mat-sidenav {
        mat-action-list {
            button {
                padding: 0 !important;
                font-size: $font-size-menu !important;
            }
        }
    }

    // Filter to convert black color in images
    // https://codepen.io/sosuke/pen/Pjoqqp
    .img-primary {
        filter: invert(38%) sepia(15%) saturate(2921%) hue-rotate(187deg) brightness(65%) contrast(100%);
    }

    .img-disabled {
        filter: grayscale(100%) saturate(0.5) opacity(0.5);
    }

    .menu-container.menu-container {
        button {
            color: $color-primary-lightest !important;
            background-color: $primary-main-color !important;

            &.mat-button-disabled {
                color: $color-grey-lightest !important;
                background-color: $primary-lighter-color !important;
            }
            &.mat-button-disabled:hover {
                color: $color-grey-lightest !important;
                background-color: $primary-lighter-color !important;
            }
        }
        button:hover {
            color: $primary-main-color !important;
            background-color: $color-primary-lightest !important;
        }
    }
}
