/*
 * Common Global CSS
 * ----------------------------------------------------------------------------
 *
 * MADE USING FOLLOWING TECHNIQUE :
 * https://stackoverflow.com/questions/50582227/angular-6-library-shared-stylesheets/57219374#57219374
 *
 */

// **************************
// Boostrap
// **************************

// Functions, variables, and mixins that are needed by other Bootstrap files
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Custom helper mixins
@import './mixins-old-cnmap';

// **************************
// Fortawesome Icons
// **************************
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/v4-shims';

// **************************
// Material Design Icons
// **************************
@import '~@mdi/font/css/materialdesignicons.min.css';
// Hack : on redéfinit la font-face en enlevant le n° de version passé en paramètre dans l'URL dans le fichier CSS d'origine,
// sinon le service worker ne sert à rien pour cacher les ressources (le paramètre dans l'URL l'empêche de servir la version mise en cache)
@font-face {
    font-family: 'Material Design Icons';
    src: url('~@mdi/font/fonts/materialdesignicons-webfont.eot');
    src: url('~@mdi/font/fonts/materialdesignicons-webfont.eot?#iefix') format('embedded-opentype'),
        url('~@mdi/font/fonts/materialdesignicons-webfont.woff2') format('woff2'),
        url('~@mdi/font/fonts/materialdesignicons-webfont.woff') format('woff'),
        url('~@mdi/font/fonts/materialdesignicons-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// **************************
// HTML & body
// **************************

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    touch-action: manipulation;
}

// **************************
// Custom utilities class
// **************************
// Note that every utility class must specify 'important' keyword

.c-pointer {
    cursor: pointer !important;
}

.c-default {
    cursor: default !important;
}

.c-grabbable {
    cursor: grab !important;

    &.c-pointer {
        // S'il y a aussi la class c-pointer, on laisse le cursor pointer par défaut
        cursor: pointer !important;
    }

    &:active {
        cursor: grabbing !important;
    }
}

.c-help {
    cursor: help !important;
}

.ws-preline {
    white-space: pre-line !important;
}

.text-small {
    font-size: 14px !important;
}

.bold {
    font-weight: bold !important;
}

.opacity-1 {
    opacity: 1 !important;
}

.no-border-radius {
    border-radius: 0 !important;
}

// Width 0
.w-0 {
    width: 0 !important;
}

// Height 0
.h-0 {
    height: 0 !important;
}

// Class to add to Bootstrap columl (col) to give it full width
.full-width-col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

// Icon along text
.icon-before-text {
    position: relative;
    top: 5px;
    @include mr(2);
}

// Overflow-wrap: anywhere (= word-break: normal + overflow-wrap: anywhere)
.text-break-anywhere {
    word-break: break-word;
}

// Object-fit properties
.object-fit-cover {
    object-fit: cover !important;
}

.object-fit-contain {
    object-fit: contain !important;
}

.object-fit-fill {
    object-fit: fill !important;
}

.object-fit-none {
    object-fit: none !important;
}

.object-fit-scale-down {
    object-fit: scale-down !important;
}

// **************************
// Customize Angular Material
// **************************

// Remove focus outlines
button:focus,
mat-button-toggle:focus,
mat-chip-list:focus,
.mat-chip-list-wrapper:focus,
.mat-list-item-content:focus,
.mat-selection-list:focus {
    outline: none !important;
}

// Buttons
button:not(.mat-icon-button):not(.mat-fab):not(.mat-mini-fab) mat-icon,
button:not(.mat-icon-button):not(.mat-fab):not(.mat-mini-fab) img {
    // More space between icon & text
    margin-right: 5px;
}

button,
mat-button-toggle {
    // Image in a bouton
    img {
        height: 24px;
        width: 24px;
    }
}

// Tooltips
.mat-tooltip {
    // Bigger font size for tooltips (10px defaut)
    font-size: 14px !important;
}

// Tables
.mat-header-cell {
    // Bigger font size for columns headers (10px defaut)
    font-size: 15px !important;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
    // Bigger font size for paginator (12px defaut)
    font-size: 14px;
}

// **************************
// Icons
// **************************
// NB : Ces règles sont en dehors du composant "IconComponent" car il faut que leur scope soit global

// Icons size & positionning
.mat-icon {
    font-size: 24px;

    &.mdi {
        line-height: 0.75em !important;
    }

    &.fa {
        font-size: 20px;
        line-height: 1.2em !important;
    }
}

.mat-icon.icon-small,
.icon-small + .mat-icon {
    font-size: 18px;
    height: 18px;
    width: 18px;

    &.fa {
        font-size: 16px;
    }
}

.mat-icon.icon-large,
.icon-large + .mat-icon {
    font-size: 36px;
    height: 36px;
    width: 36px;

    &.mdi {
        font-size: 37px;
    }

    &.fa {
        font-size: 30px;
    }
}

.mat-icon-button .mat-icon.icon-small,
.mat-icon-button .icon-small + .mat-icon {
    height: 24px;

    &.mdi,
    &.fa {
        height: 16px;
    }
}

.mat-icon-button .mat-icon.icon-large,
.mat-icon-button .icon-large + .mat-icon {
    height: 24px;

    &.mdi,
    &.fa {
        height: 36px;
    }
}
