/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
@use '@angular/material' as mat;

@import 'src/variables';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500');

$fontConfig: (
    display-4: mat.define-typography-level(112px, 112px, 300, 'Open Sans', -0.0134em),
    display-3: mat.define-typography-level(56px, 56px, 400, 'Open Sans', -0.0089em),
    display-2: mat.define-typography-level(45px, 48px, 400, 'Open Sans', 0em),
    display-1: mat.define-typography-level(34px, 40px, 400, 'Open Sans', 0.0074em),
    headline: mat.define-typography-level(24px, 32px, 400, 'Open Sans', 0em),
    title: mat.define-typography-level(20px, 32px, 500, 'Open Sans', 0.0075em),
    subheading-2: mat.define-typography-level(16px, 28px, 400, 'Open Sans', 0.0094em),
    subheading-1: mat.define-typography-level(15px, 24px, 500, 'Open Sans', 0.0067em),
    body-2: mat.define-typography-level(14px, 24px, 500, 'Open Sans', 0em),
    body-1: mat.define-typography-level(14px, 20px, 400, 'Open Sans', 0em),
    button: mat.define-typography-level(14px, 14px, 500, 'Open Sans', 0.0893em),
    caption: mat.define-typography-level(12px, 20px, 400, 'Open Sans', 0.0333em),
    input: mat.define-typography-level(inherit, 1.125, 400, 'Open Sans', 1.5px),
);

// Foreground Elements
$mat-light-theme-foreground: (
    base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text,
);

$mat-dark-theme-foreground: (
    base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3),
);

$mat-light-theme-background: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
);

// Background palette for dark themes.
$mat-dark-theme-background: (
    background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat.core($fontConfig);

// Theme Config
body {
    --primary-color: #{$primary-main-color};
    --primary-lighter-color: #{$primary-lighter-color};
    --primary-darker-color: #{$primary-darker-color};
    --text-primary-color: #{$light-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
    main: $primary-main-color,
    lighter: $primary-lighter-color,
    darker: $primary-darker-color,
    200: $primary-200-color,
    // For slide toggle,
    contrast:
        (
            main: $light-primary-text,
            lighter: $dark-primary-text,
            darker: $light-primary-text,
        ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
    --accent-color: #{$accent-main-color};
    --accent-lighter-color: #{$accent-lighter-color};
    --accent-darker-color: #{$accent-darker-color};
    --text-accent-color: #{$dark-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (
    main: $accent-main-color,
    lighter: $accent-lighter-color,
    darker: $accent-darker-color,
    200: $accent-200-color,
    // For slide toggle,
    contrast:
        (
            main: $dark-primary-text,
            lighter: $dark-primary-text,
            darker: $dark-primary-text,
        ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
    --warn-color: #{$warn-main-color};
    --warn-lighter-color: #{$warn-lighter-color};
    --warn-darker-color: #{$warn-darker-color};
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
    main: $warn-main-color,
    lighter: $warn-lighter-color,
    darker: $warn-darker-color,
    200: $warn-200-color,
    // For slide toggle,
    contrast:
        (
            main: $light-primary-text,
            lighter: $dark-primary-text,
            darker: $light-primary-text,
        ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);

@include mat.all-component-themes($theme);

.theme-alternate {
    @include mat.all-component-themes($altTheme);
    background: mat.get-color-from-palette($theme-primary);
    color: mat.get-contrast-color-from-palette($theme-primary, main);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 1.15em;
    margin: 0 0.65em;
    min-width: 3em;
    line-height: 36.4px;
}

.mat-standard-chip {
    padding: 0.5em 0.85em;
    min-height: 2.5em;
}

.material-icons {
    font-size: 24px;
    font-family: 'Material Icons', 'Material Icons';

    .mat-badge-content {
        font-family: 'Open Sans';
    }
}

// *****************
// Other variables
// *****************
$background-hover: mat.get-color-from-palette(map-get($theme, background), 'hover');
